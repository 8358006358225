exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-for-business-js": () => import("./../../../src/templates/ForBusiness.js" /* webpackChunkName: "component---src-templates-for-business-js" */),
  "component---src-templates-gots-in-action-js": () => import("./../../../src/templates/GotsInAction.js" /* webpackChunkName: "component---src-templates-gots-in-action-js" */),
  "component---src-templates-join-js": () => import("./../../../src/templates/Join.js" /* webpackChunkName: "component---src-templates-join-js" */),
  "component---src-templates-meet-gots-js": () => import("./../../../src/templates/MeetGOTS.js" /* webpackChunkName: "component---src-templates-meet-gots-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-where-to-find-js": () => import("./../../../src/templates/WhereToFind.js" /* webpackChunkName: "component---src-templates-where-to-find-js" */)
}

